import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { HttpClientModule } from '@angular/common/http';
import { NgPassOtpModule } from 'ng-pass-otp';
import { IonicStorageModule } from '@ionic/storage-angular';
import { ChooseslotPage } from './Pages/cart/chooseslot/chooseslot.page';
import { ChooseaddressPage } from './Pages/cart/chooseaddress/chooseaddress.page';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
// import { CookieService } from 'angular2-cookie/services/cookies.service';
import { CookieService } from 'ngx-cookie-service';
import { LoginmainPageModule } from './Pages/loginmain/loginmain.module';
import { OtpverifyPageModule } from './Pages/loginmain/otpverify/otpverify.module';
@NgModule({
  declarations: [AppComponent, ChooseslotPage, ChooseaddressPage],
  entryComponents: [ChooseslotPage],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgPassOtpModule,
    LoginmainPageModule,
    OtpverifyPageModule,
  ],
  exports: [NgPassOtpModule],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    CallNumber,
    GooglePlus,
    OneSignal,
    InAppBrowser,
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
