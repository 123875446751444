import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ToastController,
  LoadingController,
  ModalController,
} from '@ionic/angular';
import { ApiserviceService } from 'src/app/services/apiservice.service';
import { CartService } from 'src/app/services/cart.service';
import { NativeserviceService } from 'src/app/services/nativeservice.service';
import { StorageService } from 'src/app/services/storage.service';
import { LoginmainPage } from '../loginmain/loginmain.page';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  location: any;
  city: any;
  area: any;
  userid: any;
  cartcount: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    public element: ElementRef,
    public renderer: Renderer2,
    public http: HttpClient,
    public apiService: ApiserviceService,
    public storage: StorageService,
    public toast: ToastController,
    public cart: CartService,
    public native: NativeserviceService,
    public loadingController: LoadingController,
    public router: Router,
    public modalController: ModalController
  ) {}

  async ngOnInit() {
    await this.getUserId();
    this.getCity();
  }

  getUserId() {
    this.storage.getItem('userid').then((val) => {
      this.userid = val;
      console.log(this.userid, 'userid');
      this.cartCount();
    });
  }

  getCity() {
    // this.storage.getItem('Location').then((val) => {
    //   //console.log(val);
    //   this.location = val;
    //   // console.log(this.location);
    //   if (this.location == null) {
    //     this.router.navigateByUrl('/');
    //   } else {
    //     this.city = this.location.city;
    //     this.area = this.location.area;
    //   }
    // });
  }
  cartCount() {
    if (this.userid != 1) {
      this.apiService
        .post('cartCount', { user_id: this.userid })
        .subscribe((val) => {
          var resData: any = val;
          // console.log(resData);
          this.cartcount = resData.count;
        });
    }
  }

  goTocart() {
    if (this.userid != 1) {
      this.router.navigateByUrl('/cart');
    } else {
      // this.openLoginmodal();
      this.router.navigateByUrl('/loginmain');
    }
  }
  async openLoginmodal() {
    const modal = await this.modalController.create({
      component: LoginmainPage,
      cssClass: 'my-custom-class',
    });
    return await modal.present();
  }
}
