import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private storage: Storage) {
    this.storage.create();
  }

  getItem(item: any) {
    return this.storage.get(item);
  }

  addItem(itemkey: any, itemvalue: any) {
    return this.storage.set(itemkey, itemvalue);
  }

  // updateItem() {
  //   return this.storage.
  // }

  deleteItem(item) {
    return this.storage.remove(item);
  }
}
