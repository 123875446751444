import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { StorageService } from './services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Home', url: '/', icon: 'Home' },
    { title: 'My Profile', url: '/profile', icon: 'person-circle' },
    { title: 'My Orders', url: '/orders', icon: 'cart' },
    { title: 'My Addresses', url: '/address', icon: 'locate' },
    // { title: 'Notifications', url: '/notification', icon: 'trash' },
    // { title: 'Contact us', url: '/contact', icon: 'document' },

    { title: 'Terms and Cond.', url: '/tnc', icon: 'person-circle' },
    { title: 'FAQs', url: '/faq', icon: 'help' },
    { title: 'Privacy Policy', url: '/privacy', icon: 'finger-print' },
    { title: 'Refer App', url: 'www.google.com', icon: 'share-social' },
  ];

  oneSignalAppId: any = '431f41fb-c3e2-43e2-9203-21b9b4ae33a9';
  googleProjectId: any = '270035821587';
  userid: any;

  constructor(
    public platform: Platform,
    public router: Router,
    private oneSignal: OneSignal,
    public storage: StorageService
  ) {
    this.initializeApp();
  }
  initializeApp() {
    this.platform.ready().then(async () => {
      // this.OneSignalInit();
      this.getUserId();
      await this.storage.getItem('userid').then((val) => {
        // console.log(val);

        if (val == null || val == undefined) {
          this.storage.addItem('userid', 1);
        }
      });
    });
  }

  OneSignalInit() {
    this.oneSignal.startInit(this.oneSignalAppId, this.googleProjectId);
    this.oneSignal.inFocusDisplaying(
      this.oneSignal.OSInFocusDisplayOption.Notification
    );
    this.oneSignal.handleNotificationReceived().subscribe((res) => {
      // do something when notification is received
      // alert()
      // console.log('res', res);
    });
    this.oneSignal.handleNotificationOpened().subscribe((result) => {
      // console.log('result', result);
      // do something when a notification is opened
    });
    this.oneSignal.endInit();

    this.oneSignal.getIds().then((identity) => {
      // alert(identity.pushToken),
      // alert(identity.userId);
      // console.log(identity.pushToken);
      // console.log(identity.userId);
    });
  }

  getUserId() {
    this.storage.getItem('userid').then((val) => {
      this.userid = val;
      // console.log('yes');
    });
  }

  redirect(url: any) {
    if (this.userid == 1) {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate([url]);
    }
  }

  // menuOpened() {
  //   console.log('yes');
  // }
  menuOpened2() {
    this.getUserId();
  }
}
